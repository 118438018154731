import React, {useEffect} from 'react';
import './App.css';

function App()
{
	useEffect(() =>
	{
		document.title = "Gurminder's Portfolio"
	}, [])
	return <div className="App">
		<p>
			Welcome to my personal website! I am passionate about creating innovative and high-performing digital
			solutions tailored to meet your needs. My expertise spans across iOS development, React web
			applications, and PHP Laravel frameworks, ensuring a comprehensive and cohesive approach to your
			projects.
		</p>

		<h3>Architectural Design Patterns</h3>
		<ul>
			<li>
				<strong>Model-View-ViewModel (MVVM)</strong>: This pattern helps in separating the UI from the
				business logic, making the code more modular and testable.
			</li>
			<li>
				<strong>Coordinator</strong>: I use the Coordinator pattern to manage navigation flow, ensuring that
				view controllers remain lightweight and focused on their primary responsibilities.
			</li>
			<li>
				<strong>Protocol-Oriented Programming (POP)</strong>: This approach allows for more flexible and
				reusable code by defining protocols that describe desired behaviors.
			</li>
			<li>
				<strong>Dependency Injection</strong>: By injecting dependencies, I ensure that components are
				loosely coupled and easier to test.
			</li>
			<li>
				<strong>Repository Design Pattern</strong>: This pattern abstracts data access, providing a clean
				API for data retrieval and manipulation, which enhances the maintainability and scalability of the
				application.
			</li>
		</ul>

		<h3>iOS Development</h3>
		<p>
			As a dedicated iOS developer, I enjoy crafting seamless, user-friendly, and robust mobile applications.
			Leveraging the latest technologies and best practices, I aim to deliver apps that not only meet your
			requirements but also provide an exceptional user experience. Whether you need a simple app or a
			complex, feature-rich solution, I am here to help.
		</p>

		<h3>React Web Applications</h3>
		<p>
			Harnessing the power of React, I build dynamic, responsive, and efficient web applications. I enjoy
			creating scalable solutions that cater to your specific goals. With a focus on performance and
			usability, I strive to ensure your web application is fast, engaging, and easy to maintain.
		</p>

		<h3>PHP Laravel Expertise</h3>
		<p>
			My proficiency in PHP Laravel allows me to develop secure, reliable, and high-performing web
			applications and back-end systems. The Laravel framework enables me to build elegant and efficient
			solutions tailored to your needs. From custom web applications to complex back-end systems, I aim to
			deliver robust and scalable solutions that drive your goals forward.
		</p>

		<h3>Why Work with Me?</h3>
		<ul>
			<li>
				<strong>Experienced Professional</strong>: With extensive experience in iOS development, React, and
				PHP Laravel, I bring a wealth of knowledge and expertise to your projects.
			</li>
			<li>
				<strong>Quality Assurance</strong>: I adhere to stringent quality standards to ensure your project
				is flawless and exceeds your expectations.
			</li>
			<li>
				<strong>Client-Centric Approach</strong>: I prioritize your needs and work closely with you to
				deliver solutions that align with your objectives.
			</li>
			<li>
				<strong>Cutting-Edge Technology</strong>: I stay updated with the latest industry trends and
				technologies to provide you with state-of-the-art solutions.
			</li>
		</ul>

		<p>
			Explore my work and discover how I can help you transform your ideas into reality. Feel free to contact
			me to discuss your project and take the first step towards achieving your digital goals.
		</p>
	</div>
}

export default App;
